@font-face {
font-family: '__fontGraphik_407b4c';
src: url(/_next/static/media/f34b220f02e37fe3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__fontGraphik_407b4c';
src: url(/_next/static/media/eec065f2be8bf957-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__fontGraphik_407b4c';
src: url(/_next/static/media/3483eb270027278b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__fontGraphik_407b4c';
src: url(/_next/static/media/49dacf08a558fa01-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__fontGraphik_407b4c';
src: url(/_next/static/media/98503111ce4c0578-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__fontGraphik_407b4c';
src: url(/_next/static/media/a078d9a1e557b15d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__fontGraphik_Fallback_407b4c';src: local("Arial");ascent-override: 76.92%;descent-override: 17.11%;line-gap-override: 9.40%;size-adjust: 106.34%
}.__className_407b4c {font-family: '__fontGraphik_407b4c', '__fontGraphik_Fallback_407b4c'
}.__variable_407b4c {--font-sanserif: '__fontGraphik_407b4c', '__fontGraphik_Fallback_407b4c'
}

@font-face {
font-family: '__fontAmerigo_493485';
src: url(/_next/static/media/8a2e338bed3a146d-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__fontAmerigo_Fallback_493485';src: local("Arial");ascent-override: 100.68%;descent-override: 25.29%;line-gap-override: 0.00%;size-adjust: 93.27%
}.__className_493485 {font-family: '__fontAmerigo_493485', '__fontAmerigo_Fallback_493485'
}.__variable_493485 {--font-sans: '__fontAmerigo_493485', '__fontAmerigo_Fallback_493485'
}

